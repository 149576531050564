import React from "react";
import NavbarProjects from "../navbar/navbarProjects";
import {
  Container,
  Typography,
  Box,
  Grid,
  CardMedia,
  Card,
} from "@material-ui/core";
import { useStyles } from "../styles";
import ehmsPic1 from "../../images/ehms-pic1.jpg";
import ehmsPic2 from "../../images/ehms-pic2.JPG";
import ehmsPic3 from "../../images/ehms-pic3.gif";
import ehmsPic4 from "../../images/ehms-pic4.gif";
import Footer from "../footer";
import { Button } from '../ButtonElement'

const Ehms = () => {
  const classes = useStyles();
  return (
    <>
      <NavbarProjects />
      <Container maxWidth="md">
        <Box className={classes.box}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              
              <Typography
                variant="h4"
                gutterBottom
                className={classes.typography}
              >
                Hostel Meal System
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.typographySubtitle}
              >
                VueJS + NodeJS/Express + Sequelize + Github = The Beginning
              </Typography>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Introduction
              </Typography>
              <Typography variant="body1" className={classes.bodyPara}>
                NUS Computing offers a programme called Orbital, where students
                are encouraged to explore anything as a pair (related to
                programming, duh!) during the summer break. Most of the students
                who take this programme are year 1 students, as seniors are
                usually busy doing their summer internship. Since it is not easy
                to get an internship as a year 1 student due to our limited
                knowledge, why not make full use of our 3 months break right by
                taking up this programme?
              </Typography>

              <Typography variant="body1" className={classes.bodyPara}>
                Back in year 1, I stayed in NUS hostel and my neighbour happened
                to be a CS student. Undoubtedly, we became close and decided to
                pair up for Orbital. So what is this project about? No one will
                tell us what to do, not even the project idea. We are supposed
                to brainstorm, self-learn and code out everything ourselves. One
                day, there was an election for our hostel&apos;s committee
                members, in which their responsibilities include planning for
                hostel activities, or to be the bridge between the residents and
                hostel staffs in general. After the President&apos;s candidate
                campaign speech, many residents asked him about the issue of
                hostel meals, which this will lead us to the next section.
              </Typography>

              <Typography variant="h4" gutterBottom className={classes.title}>
                Problem
              </Typography>

              <Typography variant="body1" className={classes.bodyPara}>
                Student&apos;s perspective: Student residents are expected to
                pay a fixed amount of money to stay in their colleges/ halls at
                the start of the Semester. However, from past experiences, many
                students have underutilized credits at the time when they
                finished the Semester. <br></br>
                <br></br>
                School/ Food Caterer&apos;s perspective: The money that colleges
                or halls had received from students gave them the responsibility
                that a certain amount of food needs to be produced so as to
                accommodate meals to all the students living in the area, which
                includes weekends and recess week period. Many times during
                weekend or recess week, students choose to return home and hence
                they do not consume their meals even when they are entitled to,
                hence this leads to food wastage.
              </Typography>

              <Typography variant="h4" gutterBottom className={classes.title}>
                Solution
              </Typography>
            </Grid>
              <CardMedia
                component="img"
                image={ehmsPic1}
              ></CardMedia>
              <Typography variant="body1" className={classes.bodyPara}>
                Nonetheless, before even we can come up with a solution, we have
                to conquer the first hurdle, Github. We took a couple of days to
                learn Github so that we can collaborate effectively. Looking
                back now, our version control was done terribly (violates good
                practices of version control), but sufficient for our project to
                be done.
              </Typography>
            <Grid item xs={12}>
              <CardMedia component="img" image={ehmsPic2}></CardMedia>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.bodyPara}>
                After learning the basics of VueJS and built the front-end
                skeleton with dummy data, we planned the database schema using
                LucidChart before proceeding to do backend. Finally, I
                understand how database works in general.
              </Typography>
            </Grid>              <Card className={classes.card}>
                <CardMedia component="img" image={ehmsPic3}></CardMedia>
              </Card>
              <Typography variant="body1" className={classes.bodyPara}>
                We build a very simple dashboard. To us back then, it was still
                such an achievement to figure out how the front and backend get
                integrated. In some way or another, it trained us to be an
                effective independent learner.
              </Typography>

              <Card className={classes.card}>
                <CardMedia component="img" image={ehmsPic4}></CardMedia>
              </Card>
              <Typography variant="body1" className={classes.bodyPara}>
                The main idea of our solution is so that residents can register
                a flexible amount of quota that they want to purchase at the
                start of the semester. Students would be able to choose which
                days they would want to consume food, and whether they would
                want consume during recess week.
              </Typography>
              <Typography variant="body1" className={classes.bodyPara}>
                There are a lot more other features which I have not shown here.
                We have also built an admin module for hostel staffs to track
                consumptions, feedback forms for food, and meal plan to be shown
                to students! I think the page will be too lengthy if I
                incorporate all the other features, so if you&apos;re interested
                to find out more, you can check out the full documentation
                below.
              </Typography>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              window.open(
                "https://docs.google.com/document/d/1WKd39_IjsbN3otFYunXsvvmPAzss-GVggSzvITioh4k/edit?usp=sharing",
                "_blank"
              )
            }
          >
            FULL DOCUMENTATION
          </Button>

          <Typography variant="h4" gutterBottom className={classes.title}>
            Conclusion
          </Typography>
          <Typography variant="body1" className={classes.bodyPara}>
            A very fulfilling project indeed, especially since I had zero
            background about website development prior to this. Learning about
            the basics of version control, HTML, CSS, VueJS, NodeJS/Express, and
            databases all in 3 months. Our team&apos;s mentor wanted to push
            this project into the production level, replacing the existing
            hostel meal website which would be a huge upgrade to the current
            one. Unfortunately, it wasn&apos;t that easy to get access to the
            school&apos;s database and we understood - especially our code was
            not at a production standard back then. Nonetheless, I cannot deny
            but it was a productive summer break indeed.
          </Typography>
        </Box>
      </Container>
      <Footer />
      </>
  );
};

export default Ehms;
