import React from "react";
import NavbarProjects from "../navbar/navbarProjects";
import {
  Container,
  Typography,
  Box,
  Grid,
  CardMedia,
  Card,
} from "@material-ui/core";
import { useStyles } from "../styles";
import Footer from "../footer";
import PcsPic1 from "../../images/pcs-pic1.JPG";
import PcsPic2 from "../../images/pcs-pic2.JPG";
import { Button } from '../ButtonElement';

const buttonProject = [
  {
    name: "PROJECT REPORT",
    link:
      "https://github.com/Wincenttjoi/CS2102_2021_S1_Team21/blob/master/docs/Group_21_Final_Report.pdf",
  },
  {
    name: "PROJECT VIDEO",
    link:
      "https://github.com/Wincenttjoi/CS2102_2021_S1_Team21/blob/master/docs/CS2102%20Group%2021%20Video%20Demo.mp4",
  },
  {
    name: "PROJECT WEBSITE",
    link: "https://petcaringservices-group21.herokuapp.com/",
  },
];

const PetCaringServices = () => {
  const classes = useStyles();
  return (
    <>
      <NavbarProjects />
      <Container maxWidth="md">
        <Box className={classes.box}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.typography}
              >
                Pet Caring Services
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.typographySubtitle}
              >
                ReactJS + NodeJS/ Express + PostgreSQL + Heroku = The popular
                PERN stack
              </Typography>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Introduction
              </Typography>
              <Typography variant="body1" className={classes.bodyPara}>
                CS2102 is a database systems module, but weirdly enough, it
                requires a full stack development for the project even when
                front-end is not a pre-requisite for this module. Many students
                panicked and had to learn on the job. Fortunately my team has
                some prior experience in web development before taking this
                module.
              </Typography>
            </Grid>
            {buttonProject.map((button, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(button.link, "_blank")}
                  >
                    {button.name}
                  </Button>
                </Grid>
              );
            })}
            <Typography variant="h4" gutterBottom className={classes.title}>
              PostgreSQL
            </Typography>
            <Typography variant="body1" className={classes.bodyPara}>
              Without surprise, the main key takeaway for this project would
              definitely be PostgreSQL. From other projects, I like to process
              data on the backend (NodeJS/ Express) but in this project, we are
              forced to implement all the logic inside PostgreSQL. Apart from
              Create-Read-Update-Delete (CRUD), I also learnt on how to use more
              complex systems like Triggers and Procedures. The longest single
              trigger which I implemented was almost a hundred lines! A snippet
              of it is shown below.
            </Typography>
            <Card className={classes.card}>
              <CardMedia
                component="img"
                height="100%"
                image={PcsPic1}
              ></CardMedia>
            </Card>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Database Schema
            </Typography>
            <Typography variant="body1" className={classes.bodyPara}>
              In this module I learnt about database schema design and the
              concepts are to be applied directly in this project. Here, about
              8000 dummy data was inserted and the implementation was done by
              using complex excel formulas due to the complex constraints that
              cannot be violated. The database schema looks something like this.
            </Typography>{" "}
            <Card className={classes.card}>
              <CardMedia
                component="img"
                height="100%"
                image={PcsPic2}
              ></CardMedia>
            </Card>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Heroku
            </Typography>
            <Typography variant="body1" className={classes.bodyPara}>
              Project was sufficient to be deployed using a free version of
              Heroku as paying to host this project is definitely an overkill.
              When hosted on heroku, certain features could not work but
              strangely, it worked just fine locally. After some time debugging,
              I realized that Heroku has a default US timezone, which causes the
              API call to fail as a datetime type was being passed. It took
              awhile to realize that but - lesson learnt.
            </Typography>
            <Typography variant="h4" gutterBottom className={classes.title}>
              ESLint, Prettier, Husky
            </Typography>
            <Typography variant="body1" className={classes.bodyPara}>
              In this project, husky was used to automate script of ESLint and
              Prettier to run prior to pushing our commits to Github. This
              ensures code between developers are standardized and
              well-organized. It is only a one time set up, and you will never
              have to bother about it anymore. It is definitely worth the effort
              to integrate this as it is more time-efficient as compared to
              manually format our code.
            </Typography>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Conclusion
            </Typography>
            <Typography variant="body1" className={classes.bodyPara}>
              A lot of effort was put in for this project and I am glad that it
              paid off well. Things such as code quality (Linters, Husky, etc)
              was not enforced in this module but it was worth learning them,
              thanks to my teammate who introduced it to me.
            </Typography>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default PetCaringServices;
