import React from "react";
import ShopeeLogo from "../images/shopee-logo.jpg";
import DbsLogo from "../images/dbs-logo.jpg";
import GlobalSignInLogo from "../images/globalsignin-logo.jpg";
import EhmsLogo from "../images/ehms-logo.JPG";
import OneShelfLogo from "../images/oneshelf-logo.png";
import PcsLogo from "../images/pcs-logo.jpg";
import SimpleDBLogo from "../images/simpledb-logo.png";
import GicLogo from "../images/gic-logo.jpeg";

export const ExperiencesData = [
  {
    company: "GIC Pte Ltd",
    role: "Summer Intern, Technology",
    link: "https://www.gic.com.sg/who-we-are/",
    logo: GicLogo,
  },
  {
    company: "GlobalSignIn",
    role: "Software Engineer",
    link: "https://www.globalsignin.com/",
    logo: GlobalSignInLogo,
  },
  {
    company: "Shopee Pte Ltd",
    role: "Data Product Management",
    link: "https://careers.shopee.sg/about/",
    logo: ShopeeLogo,
  },
  {
    company: "DBS Bank Ltd",
    role: "Software Engineer",
    link: "https://www.dbs.com/about-us/default.page",
    logo: DbsLogo,
  },
];

export const ProjectsData = [
  {
    name: "SimpleDB",
    title: "CS3223 Project",
    logo: SimpleDBLogo,
    route: "projects/simpledb",
  },
  {
    name: "Pet Caring Services",
    title: "CS2102 Project",
    logo: PcsLogo,
    route: "projects/pet-caring-services",
  },
  {
    name: "OneShelf",
    title: "CS2103T Project",
    logo: OneShelfLogo,
    route: "projects/oneshelf",
  },
  {
    name: "Hostel Meal System",
    title: "Orbital (Artemis)",
    logo: EhmsLogo,
    route: "projects/ehms",
  },
];

export const ModulesData = [
  {
    code: "CS3223",
    title: <p>Database Systems Implementation</p>,
    link: "https://nusmods.com/modules/CS3223/database-systems-implementation",
  },
  {
    code: "CS4225",
    title: <p>Big Data Systems for Data Science</p>,
    link: "https://nusmods.com/modules/CS4225/big-data-systems-for-data-science",
  },
  {
    code: "ST2334",
    title: <p>Statistics and Probability</p>,
    link: "https://nusmods.com/modules/ST2334/probability-and-statistics",
  },
  {
    code: "FIN3702",
    title: <p>Investment Analysis and Portfolio Management</p>,
    link: "https://nusmods.com/modules/FIN3702A/investment-analysis-and-portfolio-management",
  },
  {
    code: "CS2103T",
    title: <p>Software Engineering</p>,
    link: "https://nusmods.com/modules/CS2103T/software-engineering",
  },
  {
    code: "CS2102",
    title: <p>Database Systems</p>,
    link: "https://nusmods.com/modules/CS2102/database-systems",
  },
  {
    code: "CS2106",
    title: <p>Operating Systems</p>,
    link: "https://nusmods.com/modules/CS2106/introduction-to-operating-systems",
  },
  {
    code: "CS2105",
    title: <p>Computer Networks</p>,
    link: "https://nusmods.com/modules/CS2105/introduction-to-computer-networks",
  },
  {
    code: "ST2137",
    title: <p>Statistical Computing and Programming</p>,
    link: "https://nusmods.com/modules/ST2137/statistical-computing-and-programming",
  },
];

//Resume
export const Resume =
  "https://drive.google.com/file/d/1iqf7l0URyRk2usAK732L99_H-GwE6LnV/view?usp=sharing";
