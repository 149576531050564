import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavImage
} from "./NavbarElements";
import { makeStyles } from "@material-ui/core/styles";
import PersonalLogo from '../../images/wincent-logo.JPG'

const useStyles = makeStyles({
  greenHover: {
    "&:hover": {
      color: "#01BF71",
    },
  },
});

const Navbar = ({ toggle }) => {
  const classes = useStyles();

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo className={classes.greenHover} to="/" onClick={toggleHome}>
            <NavImage src={PersonalLogo} />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                className={classes.greenHover}
                to="experiences"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Experiences
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                className={classes.greenHover}
                to="projects"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Projects
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                className={classes.greenHover}
                to="modules"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Modules
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
