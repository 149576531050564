import React from "react";
import { Grid } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import IconButton from "@material-ui/core/IconButton";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import InstagramIcon from "@material-ui/icons/Instagram";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import ReactGa from "react-ga"

const useStyles = makeStyles({
  footer: {
    flexGrow: 1,
    marginTop: 30,
    backgroundColor: "black",
    minHeight: "300px",
  },
  box: {
    maxWidth: "200px",
    margin: "auto",
    paddingTop: "80px",
    display: "grid",
  },
  contactFont: {
    paddingTop: "50px",
    margin: "auto",

    textAlign: "center",
    color: "#fff",
  },
  icon: {
    color: "#fff",
    "&:hover": {
      color: "#01BF71",
    },
  },
});

const openLinkedIn = () => {
  window.open("https://www.linkedin.com/in/wincent-tjoi-35a4321a0/", "_blank");
  ReactGa.event({
    category: 'Button',
    action: 'LinkedIn icon clicked'
  })
};

const openGithub = () => {
  window.open("https://github.com/Wincenttjoi", "_blank");
  ReactGa.event({
    category: 'Button',
    action: 'Github icon clicked'
  })
};

const openInstagram = () => {
  window.open("https://www.instagram.com/wincenttjoi/", "_blank");
  ReactGa.event({
    category: 'Button',
    action: 'Instagram icon clicked'
  })
};

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <h2 className={classes.contactFont}>Contact Me</h2>
      <Box className={classes.box}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Tooltip title="wincenttsjoi@gmail.com">
              <IconButton
                className={classes.icon}
                component="a"
                href="mailto:wincenttsjoi@gmail.com"
              >
                <MailIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
          <Tooltip title="LinkedIn">
            <IconButton className={classes.icon} onClick={() => openLinkedIn()}>
              <LinkedInIcon />
            </IconButton>
            </Tooltip>
            
          </Grid>
          <Grid item xs={3}>
          <Tooltip title="Github">
            <IconButton className={classes.icon} onClick={() => openGithub()}>
              <GitHubIcon />
            </IconButton>
            </Tooltip>

          </Grid>
          <Grid item xs={3}>
          <Tooltip title="Instagram">
            <IconButton
              className={classes.icon}
              onClick={() => openInstagram()}
            >
              <InstagramIcon />
            </IconButton>
            </Tooltip>

          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Footer;
