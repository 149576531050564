import React, {useEffect} from "react";
import {
  ModulesContainer,
  ModulesWrapper,
  ModulesTitle,
  ModulesCard,
  ModulesSubTitle,
  ModulesBody
} from "./ModulesElements";
import Aos from "aos";
import "aos/dist/aos.css";
import { ModulesData } from "../Data";

const openLink = (index) => {
  window.open(ModulesData[index].link, "_blank")
}

const Modules = () => {
  
  const [modulesData, setModulesData] = React.useState(ModulesData)
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])


  return (
    <ModulesContainer  id="modules">
      <ModulesTitle data-aos="fade">Modules</ModulesTitle>
      <ModulesWrapper >
        {ModulesData.map((module, index) => {
          return (
            <ModulesCard data-aos="flip-left" data-aos-duration="500" key={index} onClick={() => openLink(index)}>
              <ModulesSubTitle data-aos="zoom-in-up" data-aos-delay="500">{module.code}</ModulesSubTitle>
              <ModulesBody data-aos="zoom-in-up" data-aos-delay="500">{module.title}</ModulesBody>
            </ModulesCard>
          );
        })}
      </ModulesWrapper>
    </ModulesContainer>
  );
};

export default Modules;
