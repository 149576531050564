import styled, { keyframes } from 'styled-components'
import { MdContentPaste, MdCloudDownload } from 'react-icons/md'

export const HomeContainer = styled.div`
  margin-bottom: 200px;
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
 

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
    z-index: 2;
  }
`

export const HomeBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const VideoBackground = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`

export const ImgBackground = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`

export const HomeContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HomeTitle = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`




export const HomeQuote = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`

export const HomeBtnWrapper = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FileIcon = styled(MdContentPaste)`
  margin-left: 8px;
  font-size: 20px;
`

export const DownloadIcon = styled(MdCloudDownload)`
  margin-left: 8px;
  font-size: 20px;
`