import React, {useEffect} from 'react'
import {
  ExperiencesContainer,
  ExperiencesBody,
  ExperiencesCard,
  ExperiencesIcon,
  ExperiencesWrapper,
  ExperiencesTitle,
  ExperiencesSubTitle,
  ExperiencesJobPosition
} from './ExperiencesElements'
import { ExperiencesData } from '../Data'
import Aos from "aos";
import "aos/dist/aos.css";

const openLink = (index) => {
  window.open(ExperiencesData[index].link, "_blank")
}

const Experiences = () => {
  const [experiencesData, setExperiencesData] = React.useState(ExperiencesData)

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  return (
    <ExperiencesContainer id="experiences">
      <ExperiencesTitle data-aos="fade">EXPERIENCES</ExperiencesTitle>
      <ExperiencesWrapper>
        {experiencesData.map((experience, index) => {
          return (
            <ExperiencesCard data-aos="flip-left" data-aos-duration="500" key={index} onClick={() => openLink(index)}>
              <ExperiencesIcon data-aos="zoom-in-up" data-aos-delay="500"src={experience.logo} />
              <ExperiencesSubTitle data-aos="zoom-in-up" data-aos-delay="500">{experience.company}</ExperiencesSubTitle>
              <ExperiencesJobPosition data-aos="zoom-in-up" data-aos-delay="500">{experience.role}</ExperiencesJobPosition>
            </ExperiencesCard>
          )
        })}
      </ExperiencesWrapper>
    </ExperiencesContainer>
  )
}

export default Experiences
