import React, {useState} from 'react'
import HomeSection from '../components/home';
import Experiences from '../components/experiences';
import Projects from '../components/projects';
import Modules from '../components/modules'
import Footer from '../components/footer';
import Sidebar from './../components/sidebar'
import Navbar from './../components/navbar'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HomeSection />
      <Experiences />
      <Projects />
      <Modules />
      <Footer />
    </>
  )
}

export default Home
