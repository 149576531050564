import React, { useState, useEffect } from 'react'
import { Button } from '../ButtonElement'
import Video from '../../videos/homesection_video.mp4'
import { HomeContainer,
  HomeBackground, 
  VideoBackground,
  HomeContent,
  HomeTitle,
  HomeQuote,
  HomeBtnWrapper,
  FileIcon,
  DownloadIcon, 
  ImgBackground 
} from './HomeElements'
import { Resume } from '../Data'
import Personal from "../../images/personal.webp"
import ReactGa from "react-ga"
import Typewriter from "typewriter-effect";
import Aos from "aos";
import "aos/dist/aos.css";

const HomeSection = () => {
  const [hover, setHover] = useState(false)

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const onHover = () => {
    setHover(!hover)
  }

  const openResume = () => {
    window.open(Resume, "_blank")
    ReactGa.event({
      category: 'Button',
      action: 'Resume button clicked'
    })
  }

  return (
    <HomeContainer>
      <HomeBackground>
        {/* <VideoBackground autoPlay loop muted src={ Video }
        type='video/mp4' /> */}
        <ImgBackground src={Personal}></ImgBackground>
      </HomeBackground>
      <HomeContent>
        <HomeTitle>
          <Typewriter
            options={{ delay: 40 }}
            onInit={(typewriter) => {
              typewriter.typeString("Wincent Tjoi").pauseFor(1000).start()
            }}
          />
        </HomeTitle>
        {/* <HomeQuote>
          <Typewriter
            options={{ delay: 35 }}
            onInit={(typewriter) => {
              typewriter.pauseFor(500).typeString("Innovate. Integrate. Inspire.").start()
            }}
          />
        </HomeQuote> */}
        <HomeBtnWrapper   
          data-aos="fade"
          data-aos-delay="1000">
          <Button
          onClick={ openResume }
          onMouseEnter={onHover}
          onMouseLeave={onHover}
          primary='true'
          dark='true'
          >
            My Resume
            {hover ? <DownloadIcon /> : <FileIcon />} 
          </Button>
        </HomeBtnWrapper>
      </HomeContent>
    </HomeContainer>
  )
}

export default HomeSection
