import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/index";
import PetCaringServices from "./components/projects/PetCaringServices";
import Ehms from "./components/projects/Ehms";
import OneShelf from "./components/projects/OneShelf";
import SimpleDB from "./components/projects/SimpleDB";
import ScrollToTop from "./ScrollToTop";
import ReactGa from "react-ga"

function App() {
  useEffect(() => {
    ReactGa.initialize('UA-196310059-1')
    ReactGa.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route
              path="/projects/pet-caring-services"
              component={PetCaringServices}
              exact
            />
            <Route path="/projects/ehms" component={Ehms} exact />
            <Route path="/projects/oneshelf" component={OneShelf} exact />
            <Route path="/projects/simpledb" component={SimpleDB} exact />
          </Switch>
        </ScrollToTop>
    </Router>
  );
}

export default App;
