import React from "react";
import NavbarProjects from "../navbar/navbarProjects";
import {
  Container,
  Typography,
  Box,
  Grid,
  CardMedia,
  Card,
  Link
} from "@material-ui/core";
import { useStyles } from "../styles";
import simpledbData from "../../images/simpledb-datas.png";
import simpledbParserDiagram from "../../images/simpledb-parserdiagram.jpeg";
import sequenceparser from "../../images/simpledb-parsersequence.png";
import issatisfied from "../../images/simpledb-issatisfied.png";
import createindex from "../../images/simpledb-createindex.png";
import indexsnippet from "../../images/simpledb-indexsnippet.png";
import heuristicplanner from "../../images/simpledb-heuristicplanner.png";
import planclass from "../../images/simpledb-planclass.png";
import Footer from "../footer";
import { Button } from '../ButtonElement';

const SimpleDB = () => {
  const classes = useStyles();
  return (
    <>
      <NavbarProjects />
      <Container maxWidth="md">
        <Box className={classes.box}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.typography}
              >
                SimpleDB
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.typographySubtitle}
              >
                Java = The Ultimate Language
              </Typography>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Introduction
              </Typography>
              <Typography variant="body1" className={classes.bodyPara}>
                This SimpleDB Project original codebase was built by &nbsp;
                <Link href="http://www.cs.bc.edu/~sciore/simpledb/" variant="body1" className={classes.bodyPara}>
                Edward Sciore, 
                Database Design and Implementation (Second Edition)</Link>&nbsp;
                spanned roughly about ~14k LoC,
                and it is not related to AmazonDB in any manner. 
                The project was meant for me to better understand
                how databases are implemented behind the scene, which ranges from  
                parser, query processing, indexes to database planner. Rather than just showing 
                only the features that was built, theoretical concepts of database implementation
                will also be covered which I feel will be beneficial to people who are 
                not exposed to the different components of a database.
                Note that not everything covered here is exhaustive of how a database is 
                actually implemented. It is a simplified version of a database, hence the name SimpleDB.
                <br></br>
                <br></br>
                The data shown below will be used to illustrate queries used.
              </Typography>

              <CardMedia
                component="img"
                image={simpledbData}
              ></CardMedia>

              <Typography variant="h4" gutterBottom className={classes.title}>
                Extension 1: Support for non-equality predicate
              </Typography>
              

              <Typography variant="body1" className={classes.bodyPara}>
                Initially, SimpleDB only supports equality predicates. In other words, it cannot 
                support the following predicates in the where clause: <br></br>
                - SId {">"}= 10 <br></br>
                - Sname != &quot;joe&quot; <br></br><br></br>
                We first need to understand how a parser and lexer works for the program to 
                interpret a query.
              </Typography>
              <CardMedia
                component="img"
                image={simpledbParserDiagram}
              ></CardMedia>

              <Typography variant="body1" className={classes.bodyPara}>
                A lexer is responsible to take in character by character and form into "chunks",
                which is also called tokens. Each token has a type and a value. In SimpleDB,
                the lexical analyzer supports token types such as delimiters (comma), 
                integer constants, string constants, keywords (select, from, where, etc) and 
                identifiers which can be any token unindentified like table names, field names, etc.
                <br></br><br></br>
                Parser is then responsible to make sense of the tokens that it receives, 
                ensuring that they are valid as a sentence and does not violate any grammar rules.
                For example, the query: select "field" to STUDENT - will fail, as the grammar
                enforces that after the field tokens, the parser needs to eat a special keyword token
                "FROM" rather than an identifier "to" STUDENT. Eating a token means that the parser 
                has validated the token successfully and move on to the next token in the sentence, 
                if any.<br></br><br></br>
                When the parser eats the query tokens, it consolidates the necessary data into 
                QueryData object, which will be sent to the query planner to decide on what plan to use 
                based on the QueryData. Some examples of important contents that QueryData store would be like:
                fields, tables, and predicate. <br></br>
                If you are interested in learning more in-depth about parsing and lexical analyser, you may refer&nbsp;
                <Link href="https://tomassetti.me/guide-parsing-algorithms-terminology/#:~:text=A%20lexer%20and%20a%20parser,trying%20to%20parse%20an%20addition." variant="body1" className={classes.bodyPara}>
                here</Link>.
              </Typography>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  image={sequenceparser}
                ></CardMedia>
              </Card>

              <Typography variant="body1" className={classes.bodyPara}>
                A query can have one predicate, and each predicate can hold one or more terms.
                For example, the query "select sname from STUDENT where sid {">"} 2 and sname != 'bob'" 
                shows that the first term is sid {">"} 2 and the second term to be sname != 'bob'.
                eatOpr(String) method is added in Lexer class, and is validated using an
                internal call to matchOpr(String) method. Term is also modified that it now
                supports an operator field when initialized.
              </Typography>

              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  image={issatisfied}
                ></CardMedia>
              </Card>

              <Typography variant="body1" className={classes.bodyPara}>
                The snippet above shows the changes made on isSatisfied method in Term class.
                A scan is basically like a pointer to a record in a table, which can iterate
                to the next record one at a time until no more record is found in the table.
                When quering the next record, isSatisfied is called to check that the next
                record meets the term's condition based on the new operators supported.
                The Scan component will be further discussed in details in later section.
              </Typography>

              <Typography variant="h4" gutterBottom className={classes.title}>
                Extension 2: Support for Hash and B+ Tree Index
              </Typography>

              <Typography variant="body1" className={classes.bodyPara}>
                Indexing is a method for users to efficiently find records in a table 
                based on a particular field value, and indexes needs to be preprocessed 
                since you need a version of the table organized in that field. 
                A table can have one or more indexes, each defined on a separate field(s).
                For example, an index on STUDENT's MajorId field will make it easy for user
                to find STUDENT records having a given major. In a way, indexing trades 
                query efficiency to space, as you have to store more tables when doing indexing.
                There are 2 types of indexes, namely Hash Index and B+ Tree index, but these 
                concepts will not be covered here.
                <br></br><br></br>
                The nitty gritty of the index implementation was already done from the given
                codebase. This extension is meant for user to have the ability to choose 
                which index they would want to use for a particular field(s) in a table.
                For example, user can use: "create index 'indexname' on 'tablename' ('fieldname') using hash".
                The parsing section will not be covered again for this section, as the idea 
                is similar to that described in Extension 1.
              </Typography>

              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  image={createindex}
                ></CardMedia>
              </Card>

              <Typography variant="body1" className={classes.bodyPara}>
                IndexManager stores index metadata in the catalog table called idxcat.
                The constructor is called during system startup and creates the catalog table if the database is new. 
                createIndex and getIndexInfo methods will open a table scan on the catalog table. 
                The method createIndex inserts a new record into the table while 
                getIndexInfo searches the table for those records having the specified table name and inserts them into the map.
              </Typography>

              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  image={indexsnippet}
                ></CardMedia>
              </Card>

              <Typography variant="body1" className={classes.bodyPara}>
                IndexInfo now not only receives the name of the index and field, but also the type of the index specified (B+ tree / Hash)
                based on data given from parser. The method open() in IndexInfo opens the index depending on idxtype constructed earlier.
                The method blocksAccessed estimates the search cost of the index. 
                It first uses the index's Layout information to determine the length of each index record and estimate 
                the records per block (RPB) of the index and the size of the index file. 
                Then it calls the index-specific method searchCost to calculate the number 
                of block accesses for that index type.
              </Typography>


              <Typography variant="h4" gutterBottom className={classes.title}>
                Extension 3: Support for DISTINCT and displaying query plan
              </Typography>

              <Typography variant="body1" className={classes.bodyPara}>
                Distinct can be implemented by using hashing or sorting method. In my implementation, only the sorting method was used.
                In a real database, the planner would create both plans for the query and use their metadata to select
                the more efficient plan.
                For sorting method, the idea is to sort all the records based on the 
                fields that needs to be projected, and a linear check 
                of each record is done to ensuring that there is no duplicate in records.
                Should the next record is a duplicate, the next() method will just be called again to 
                move to the record further down in the table.
              </Typography>


              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  image={planclass}
                ></CardMedia>
              </Card>

              <Typography variant="body1" className={classes.bodyPara}>
                During the first step of query processing, the parser extracts the relevant data from an SQL statement. 
                The next step is to turn that data into a relational algebra query tree. This step is called planning.
                The SimpleDB object that calculates the cost of a query tree is called a plan. Plans implement the interface Plan
                which supports the methods like blocksAccessed, recordsOutput and distinctValues.
                Furthermore, every plan has the method open(). The open() method is generally broken down into 4 parts.
                First, it will open up the plan further down in the tree to retrieve the record from earlier plan (remember that
                a plan is a query tree). Second, the plan will call printPlan() which lets the user know that the current plan
                is being used. Third, perform its own plan and scan. Lastly, close the plan. Plans and scans are conceptually similar, 
                in that they both denote a query tree. The difference is that a plan accesses the metadata of 
                the tables in the query, whereas a scan accesses their data. The above class diagram shows the different plans
                built on SimpleDB, but note that these plans are non-exhaustive.
              </Typography>

              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  image={heuristicplanner}
                ></CardMedia>
              </Card>

              <Typography variant="body1" className={classes.bodyPara}>
                Referring to the last optional section in the sequence diagram, isDistinct is a new addition to SimpleDB, 
                and when found to be true from QueryData, SortPlan and DistinctPlan will be executed when doing the query.
                In the next() method in DistinctPlan, nextDistinct() is called to ensure that the next record is not 
                a duplicate from the previous record, where a check is done for every fields in the record output. 
                2 variables of Constant:List is used to store the previous and current record. 
              </Typography>
            </Grid>

          </Grid>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() =>
              window.open(
                "https://docs.google.com/document/d/1WKd39_IjsbN3otFYunXsvvmPAzss-GVggSzvITioh4k/edit?usp=sharing",
                "_blank"
              )
            }
          >
            FULL DOCUMENTATION
          </Button> */}

          <Typography variant="h4" gutterBottom className={classes.title}>
            Conclusion
          </Typography>
          <Typography variant="body1" className={classes.bodyPara}>
            This project is pretty challenging as the concepts are not taught during lectures. I had to spend many hours just trying
            to understand the codebase that is relatively huge, what each component is responsible for, and I even had to read the textbook 
            by the author, Edward Sciore. Although it is challenging, this project has definitely made me  
            to be more appreciative and also have better grasp of the underlying structure of a database, 
            which many software engineers have taken for granted nowadays.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default SimpleDB;
