import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  typography: {
    fontFamily: "Encode Sans Expanded",
    textAlign: "center",
  },
  typographySubtitle: {
    fontFamily: "Lucida Console",
    textAlign: "center",
    paddingBottom: "80px",
  },
  title: {
    paddingTop: "30px",
    fontFamily: "Garamond",
  },
  box: {
    padding: "20px",
  },
  bodyPara: {
    paddingBottom: "25px",
    paddingTop: "25px",
    fontSize: "18px",
    fontFamily: "Garamond",
    letterSpacing: "0.03em",
    fontColor: "#382513",
    lineHeight: "180%",
  },
  card: {
    border: "none",
    boxShadow: "none",
    minWidth: "100%",
  },
  card2: {
    border: "none",
    boxShadow: "none",
    maxHeight: "50%",
  },
});


