import React from "react";
import NavbarProjects from "../navbar/navbarProjects";
import {
  Container,
  Typography,
  Box,
  Grid,
  CardMedia,
  Card,
} from "@material-ui/core";
import { useStyles } from "../styles";
import Footer from "../footer";
import OneShelfPic1 from "../../images/oneshelf-logo.png";
import OneShelfPic2 from "../../images/oneshelf-pic2.JPG";
import OneShelfPic3 from "../../images/oneshelf-pic3.png";
import OneShelfPic4 from "../../images/oneshelf-pic4.png";
import OneShelfPic5 from "../../images/oneshelf-pic5.JPG";
import OneShelfPic6 from "../../images/oneshelf-pic6.JPG";
import OneShelfPic7 from "../../images/oneshelf-pic7.JPG";
import OneShelfPic8 from "../../images/oneshelf-pic8.JPG";
import OneShelfPic9 from "../../images/oneshelf-pic9.JPG";
import { Button } from '../ButtonElement';

const OneShelf = () => {
  const classes = useStyles();

  return (
    <>
      <NavbarProjects />
      <Container maxWidth="md">
        <Box className={classes.box}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.typography}
              >
                OneShelf
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.typographySubtitle}
              >
                JavaFX + JUnit + Travis + PlantUml = The Old School
              </Typography>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Introduction
              </Typography>
              <Typography variant="body1" className={classes.bodyPara}>
                This project was completed as part of CS2103T module
                requirement. The tech stacks used were pretty old school as
                described on the heading above. JavaFX.. PlantUml..? These
                machines are pretty much outdated and not as powerful as
                compared to ReactJS or other documentation tools such as
                Confluence. Regardless, this is a module project and there are
                still many opportunities to be learnt. We were initially given a
                codebase of ~5-6k LoC and expected to enhance on its features.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardMedia component="img" image={OneShelfPic2}></CardMedia>
              </Card>
              <Typography className={classes.pictureDescription}>
                Before
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardMedia component="img" image={OneShelfPic1}></CardMedia>
              </Card>
              <Typography className={classes.pictureDescription}>
                After
              </Typography>
            </Grid>

            <Typography variant="body1" className={classes.bodyPara}>
              The initial product was an address book but it was then modified
              to help restaurant owners keep track of their inventories, and any
              pending deliveries that has yet to be sent out to customers. The
              project idea is very simple, so instead of describing its
              features, I will just pen down what I have learnt from this
              module.
            </Typography>

            <Typography variant="h4" gutterBottom className={classes.title}>
              Version Control
            </Typography>
            <Card className={classes.card}>
              <CardMedia component="img" image={OneShelfPic4}></CardMedia>
            </Card>

            <Typography variant="body1" className={classes.bodyPara}>
              From this module, I was then able to appreciate the true power of
              Github. Since we were working on this project in a team of 5, good
              version control practices were important and we also did thorough
              code review before merging the branches. We tried both forking and
              branching workflow - but in this project, we decided to use
              branching work flow due to the small nature of the project and
              tight deadlines.
            </Typography>
            <Card className={classes.card}>
              <CardMedia component="img" image={OneShelfPic3}></CardMedia>
            </Card>
            <Typography variant="body1" className={classes.bodyPara}>
              We have integrated our repository with Travis CI/CD before a new
              version can be published. Apart from that, we have also included
              CodeCov to ensure thorough testing before pushing a new version.
              Should there be a sudden drop in our test coverage, the build will
              fail and this ensures strong unit testing in our project. The code
              coverage is well below 90%, as testing requirements for this
              module are only for backend (no frontend). Not the best result,
              but I have learnt a useful tool in this project. You can see the
              coverage as shown below.
            </Typography>
            <Card className={classes.card}>
              <CardMedia component="img" image={OneShelfPic5}></CardMedia>
            </Card>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Documentation
            </Typography>
            <Typography variant="body1" className={classes.bodyPara}>
              Documentation is another aspect that was largely emphasized in
              this project. Just looking at the size of the codebase, proper
              code design or structure is required to ensure good code quality
              for maintainability. My team and I documented UML diagrams and
              charts using PlantUml so that other developers would be able to
              continue building on the diagrams iteratively in the future. Some
              examples of the diagrams are shown below.
            </Typography>

            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  height="240px"
                  image={OneShelfPic6}
                ></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  height="240px"
                  image={OneShelfPic7}
                ></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  height="240px"
                  image={OneShelfPic8}
                ></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  height="240px"
                  image={OneShelfPic9}
                ></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Conclusion
              </Typography>
              <Typography variant="body1" className={classes.bodyPara}>
                Although the tech stack in this project is pretty niche and not
                widely used, the key takeaways for this project is definitely
                version control and proper documentation. We cannot deny that
                with bad documentation, it is difficult for new developers to
                maintain the application.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    "https://ay2021s1-cs2103t-t12-1.github.io/tp/",
                    "_blank"
                  )
                }
              >
                FULL DOCUMENTATION
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default OneShelf;
