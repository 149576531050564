import React, {useEffect} from "react";
import {
  ProjectsContainer,
  ProjectsCard,
  ProjectsIcon,
  ProjectsWrapper,
  ProjectsTitle,
  ProjectsSubTitle,
  ProjectsJobPosition,
} from "./ProjectsElements";
import Aos from "aos";
import "aos/dist/aos.css";
import { ProjectsData } from "../Data";

const openLink = (index) => {
  window.open(ProjectsData[index].route, "_blank")
}

const Projects = () => {
  const [projectsData, setProjectsData] = React.useState(ProjectsData);
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
      <ProjectsContainer id="projects">
        <ProjectsTitle data-aos="fade">Projects</ProjectsTitle>
        <ProjectsWrapper>
          {projectsData.map((project, index) => {
            return (
              <ProjectsCard data-aos="flip-left" data-aos-duration="500" key={index} onClick={() => openLink(index)}>
                <ProjectsIcon data-aos="zoom-in-up" data-aos-delay="500" src={project.logo}/>
                <ProjectsSubTitle data-aos="zoom-in-up" data-aos-delay="500">{project.name}</ProjectsSubTitle>
                <ProjectsJobPosition data-aos="zoom-in-up" data-aos-delay="500">{project.title}</ProjectsJobPosition>
              </ProjectsCard>
            );
          })}
        </ProjectsWrapper>
      </ProjectsContainer>
  );
};

export default Projects;
