import React from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavImage
} from "./NavbarElements";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from "@material-ui/core/styles";
import PersonalLogo from '../../images/wincent-logo.JPG'

const useStyles = makeStyles({
  icon: {
    fontSize:"48px",
    "&:hover" : {
      color: "#01BF71",
      fontSize: "56px"
    }
  }
});

const NavbarProjects = () => {
  const classes = useStyles();

  return (
    <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            {/* <ArrowBackIcon className={classes.icon}/> */}
            <NavImage src={PersonalLogo} />
          </NavLogo>
        </NavbarContainer>
    </Nav>
  );
};

export default NavbarProjects;
